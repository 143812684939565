<template>
	<div class="wrap">
		<div class="header">
			<div class="top top_mafi f-left">
				<div class="util">
					<button class="arrow_white" @click="$emit('goBack')">
						<i class="icon icon-arrow-left"></i>
					</button>
				</div>
				<h2 class="page_tit">Google OTP {{ text_reg }}</h2>
			</div>
		</div>
		<div class="section_wrap pt-50">
			<div class="subindex_wrap">
				<div class="flex_wrap">
					<div class="intro_content maf005">
						<div class="intro_top bg_w character3">
							<div class="text_guide pt-20">
								<h4>Google OTP</h4>
							</div>
							<div class="text_guide_desc pt-15">
								<p class="text">{{  $language.auth.sns_auth_require }}<br>
									{{ message_01 }}</p>
							</div>
						</div>
					</div>
				</div>
				<div class="intro_btn_wrap">
					<div class="social_login">
						<button
							class="btn_l btn_kakao"
							@click="loginWith('kakao')"
						>{{ $language.kakao.login }}</button>
						<button class="btn_l btn_google" @click="loginWith('google')">{{ $language.google.login }}</button>
						<button class="btn_l btn_apple black" @click="loginWith('apple')">{{ $language.apple.login }}</button>

						<vue-apple-login
							v-show="false"

							:onSuccess="handleAppleLoginSuccess"
							:onFailure="handleAppleLoginFail"
						></vue-apple-login>
					</div>
				</div>
			</div>
		</div>

		<Mafai1114
			v-if="is_1114"
			:user="user"

			@click="toMypage"
			style="position: fixed; left: 0; top: 0; background-color: white; overflow: auto"
		></Mafai1114>
	</div>
</template>

<script>

	import Mafai1114 from '@/view/Mypage/mafia111-4'

export default {
	name: 'mafia1113'
	, props: ['user']
	, components: { Mafai1114 }
	, data: function(){
		return {
			program: {
				name: this.$language.auth.re_register_otp_google
				, title: this.$language.auth.re_register_otp_google
				, not_header: true
				, not_footer: true
				, type: 'mypage'
			}
			, social_code: ''
			, is_1114: false
			, is_pin: false
			,pin_option: {
				pin_type: 'check'
			},
      message_01: ''
		}
	}

	, computed: {
		text_reg: function(){
			let t = '등록'
			if(this.user.gogle_otp_reg_fg == 'Y'){
				t = '재등록'
			}

      let lang = localStorage.getItem('language')
      if (lang === 'en') {
        t = 'registration'
        if(this.user.gogle_otp_reg_fg == 'Y'){
          t = 're-registration'
        }
      }

			return t
		}
	}
	, methods: {
    checkLang() {
      let lang = localStorage.getItem('language')
      if (lang === 'en') {
        this.message_01 = this.text_reg + ' Google OTP after authentication.'
      } else {
        this.message_01 = '인증 후 Google OTP를 '+ this.text_reg + ' 합니다.'
      }
    },


		loginWith: async function(type){
			console.log('loginWith')
			if(type == 'kakao'){
				this.social_code = 'MB00400001'
				try{
					this.$common.inAppWebviewCommunity('android', 'sign_in_kakao')
				}catch(e){
					console.log('kakao login error', e)
					await this.handleSignInKakao()
				}

			}else if(type == 'google'){
				this.social_code = 'MB00400003'
				try{
					this.$common.inAppWebviewCommunity('android', 'sign_in_google')
				}catch(e){
					console.log('google login error', e)
					await this.handleClickUpdateScope2()
				}
			}else if(type == 'apple'){
				this.social_code = 'MB00400002'
				// next
				try{
					this.$common.inAppWebviewCommunity('android', 'sign_in_apple')
				}catch(e){
					document.getElementById('appleid-signin').click()

					console.log('google login error', e)
				}
			}
		}

		, async handleSignInKakao() {
			if(!window.Kakao.isInitialized()){
				window.Kakao.init(process.env.VUE_APP_KEY_KAKAO_JAVASCIRPT)
			}

			let t = false
			if(t){
				let REST_API_KEY = process.env.VUE_APP_KEY_KAKAO_REST
				let REDIRECT_URI = this.kakao_return_url
				window.document.location.href = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&state=1234`
			}else{
				console.log(window.Kakao.isInitialized());
				console.log('kakao token: ' + window.Kakao.Auth.getAccessToken())
				await this.kakaoLogin()
			}
		}


		,kakaoLogin: async function(){
			let self = this
			window.Kakao.Auth.loginForm({
				success: function(authObj) {
					this.kakao_info = authObj
					console.log(this.kakao_info, authObj)
					console.log('kakao login token', window.Kakao.Auth.getAccessToken())

					self.loginWithSocial(authObj)
				},
				fail: function(err) {
					console.log(err)
				},
			})
		}

		, loginWithSocial: async function(e){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_confirm_social
					, data: {
						member_number: this.user.member_number
						, member_social_certfc_code: this.social_code
						, social_certfc_code: e.access_token
					}
					, type: true
				})

				if(result.success){
					this.token_info = result.data
					// console.log('this.token_info', this.token_info)

					let certfc = this.$encodeStorage.getSessionCertfc()

					if(!certfc){
						throw 'not certfc'
					}else{
						if(result.data.session_token){
							await this.$encodeStorage.setSessionToken(result.data.session_token)
						}
						this.$bus.$emit('onPin', 'otp_pin_check')
						this.$bus.$on('pinCallback', this.pinClick)
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		,pinClick: async function(){
			this.is_pin = false
			this.is_1114 = true
		}
		,pinCancel: function(){
			console.log('cancel')
			this.is_pin = false
		}
		, async handleClickUpdateScope2() {
			console.log('handleClickUpdateScope2')

			const option = new window.gapi.auth2.SigninOptionsBuilder();
			option.setScope("email https://www.googleapis.com/auth/drive.file");
			const googleUser = await this.$gAuth.GoogleAuth.currentUser.get()
			let t = googleUser.getAuthResponse();
			try {
				let result = await googleUser.grant(option);
				console.log("result", result);

				if(!t.access_token){
					t = googleUser.getAuthResponse();
					console.log("t re!!", t);
				}

				let r = {
					type : 'google'
					, access_token: t.access_token
				}

				await this.loginWithSocial(r);

				//await this.joinWithGoogle(googleUser.Cc.access_token, googleUser.Av.mw)
			} catch (error) {
				console.error(error);
			}
		}
		,toMypage: function(){
			this.is_pin = false
			this.is_1114 = false
			this.$bus.$emit('getUserInfo', this.user)
			this.$bus.$emit('to', { name: 'myPage'})
		}
		, handleAppleLoginSuccess: function(data){
			let r = {
				type : 'apple'
				, access_token: data.authorization.code
			}

			this.loginWithSocial(r);
		}
		, handleAppleLoginFail: function(error){
			console.log(error)
			this.$bus.$emit('notify', { type: 'error', message: this.$language.apple.login_failed})
		}

	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
    this.checkLang()
		window.sign_in_social = (e) => {
			try{
				this.loginWithSocial(e)
			}catch (e) {
				console.log(e)
			}
		}

		this.$bus.$on('pinCallback', (pin_type) => {
			switch (pin_type){
				default:
				case 'check':
					this.$bus.$emit('offPin')
					this.is_1114 = true
					break
			}
		})
	}
}
</script>